<template>
	<div v-if="showModal && !noModal">
		<div class="modal">
			<div class="modal__overlay" @click="closeModal">
			</div>

			<div class="modal__content">
				<div class="modal-img">
					<slot name="modalImage">
						<!-- defaults to an image-bg (this would be overwritten if an image were passed in directly) -->
						<div class="modal-img__bg" v-bind:style="{backgroundImage: 'url('+imgBg+')'}">
				  		</div>	
					</slot>
					
					<button class="btn btn--link" @click="closeModal" aria-label="Close popup">
						X
					</button>
				</div>

				<div class="modal-copy">
					<slot name="header"></slot>
					<slot name="content"></slot>
					<slot name="footer"></slot>
				</div>
			</div>
		</div>
	</div>

	<div class="image-link" :class="{'image-link--noModal': noModal}" @click="showModal = true">
		<div class="image-link__img">
			<slot name="image">
			</slot>
		</div>
		<div class="image-link__title">
			<slot name="title">
			</slot>
		</div>
	</div>

</template>

<script>
	// unsure if this would ever initialize before window...
	let modalTarget = window ? window.location.hash : null;

	export default {
		name: 'Modal',
		props: ['imgBg', 'noModal', 'anchor'],
		methods:{
			closeModal(){
				this.showModal = false
			}
		},
		data () {
			return {
				showModal: false
			}
		},
		mounted() {
			if (modalTarget === this.anchor) {
				this.showModal = true;
			}
		}
	};
</script>