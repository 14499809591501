<template>
	<div class="image-grid">
		<div class="image-grid__item" v-for="image in images">
			<Modal :imgBg="image.modalImage" :noModal="image.noModal" :anchor="image.anchor">
				<template v-slot:header>
				<h2>{{image.title}}</h2>
			  </template>
				<template v-slot:content>
					<p v-for="string in image.content" v-html="string"></p>
				</template>
				<template v-slot:title>
					<p v-if="image.title.length">{{image.title}}</p>
				</template>
				<template v-slot:image>
					<img v-bind:src="image.image" :alt="image.alt" />
				</template>
				<template v-slot:modalImage>
					<!-- <img v-bind:src="image.modalImage" /> -->
				</template>
			</Modal>
		</div>		
	</div>
</template>
<script>
	import Modal from './Modal.vue'

	export default {
		name: 'ImageGrid',
		props: ['images'],
		components: { Modal },
		data () {
			return {
				
			}
		}
	};
</script>