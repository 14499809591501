export default [
	{
		title: "Marine Layer",
		alt: "Marine Layer custom branded sustainable B Corp swag curated by Swagger.",
		anchor: "#marine-layer",
		image: require('../../../images/brands-we-love/marine-layer.jpg'),
		modalImage: require('../../../images/brands-we-love/modal-images/marine-layer.png'),
		content: [
			"We love Marine Layer for their extreme softness–and their “7-day weekend kinda lifestyle”. Their Re-Spun program is all about keeping textiles out of landfills. Recycled tees = recycled cotton.",
			"<b>BOTTOM LINE:</b> Swag with style never felt so soft–and sustainable.",
			"<a href='/marine-layer'>See More ></a>"
		]
	},
	{
		title: "Nike",
		alt: "Nike custom branded apparel curated by Swagger.",
		anchor: "#nike",
		image: require('../../../images/brands-we-love/nike.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/nike.jpg'),
		content: [
			"Nike on sustainability: “to consider the complete design solution…how we source it, make it, use it, return it…reimagine it.” That’s where the Nike “Move to Zero” initiative comes in: zero carbon and zero waste.",
			"<b>BOTTOM LINE:</b> When it comes to cool swag that also protects the environment, we agree: Just do it.",
			"<a href='/nike'>See More ></a>"
		]
	},
	{
		title: "S'well",
		alt: "S'well custom branded water bottles curated by Swagger.",
		anchor: "#swell",
		image: require('../../../images/brands-we-love/swell.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/swell.jpg'),
		content: [
			"S’well didn’t break the mold, they invented it. And they did it with style and ingenuity, fusing form and function to make a water bottle that is every much a fashion statement as it is climate-saving hero.",
			"<b>BOTTOM LINE:</b> For water bottles with your brand name–and real swagger–think S’well.",
			"<a href='/swell'>See More ></a>"
		]
	},
	{
		title: "The North Face",
		alt: "The North Face custom branded apparel curated by Swagger.",
		anchor: "#the-north-face",
		image: require('../../../images/brands-we-love/thenorthface.jpg'),
		modalImage: require('../../../images/brands-we-love/modal-images/thenorthface.png'),
		content: [
			"The North Face is far more than a brand of best-in-class outdoor apparel and gear. The company is commited to reducing their carbon footprint and exploring new ideas and solutions to minimize their impact.",
			"<b>BOTTOM LINE:</b> This brand designs, works and lives by their principles. Inspiring.",
			"<a href='/the-north-face'>See More ></a>"
		]
	},
	{
		title: "Nimble",
		alt: "Nimble custom branded wireless charger, sustainable tech, recycled plastic curated, designed and produced by Swagger.",
		anchor: "#nimble",
		image: require('../../../images/brands-we-love/nimble.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/nimble.png'),
		content: [
			"The tech company of the future is here now. Nimble mobile charging gadgets look great and work great. But that’s not enough. Nimble believes in “Tech for Good”, so their products are sustainably made, their packaging is plastic-free and when you’re finished with them, they’re fully recyclable.",
			"<b>BOTTOM LINE:</b> Nimble makes useful look cool–and makes balancing our modern needs with those of the planet possible.",
			"<a href='/nimble'>See More ></a>",
		]
	},
	{
		title: "Herschel",
		alt: "Herschel custom branded RPET bags decorated by Swagger.",
		anchor: "#herschel",
		image: require('../../../images/brands-we-love/herschel.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/herschel.jpg'),
		content: [
			"At Herschel, they like to say, “From the landfill, with love.” Clearly, we’re on the same page. Herschel’s supply chain respects human rights and requires high levels of transparency from factories. And we love how this attention to detail and high standards also extends to their timeless, well-designed products.",
			"<b>BOTTOM LINE:</b> Whether it’s backpacks or the planet, the extra effort is always worth it.",
			"<a href='/herschel'>See More ></a>",
		]
	},
	{
		title: "Carhartt",
		alt: "Carhartt custom branded apparel embroidered by Swagger.",
		anchor: "#carhartt",
		image: require('../../../images/brands-we-love/carhartt.jpg'),
		modalImage: require('../../../images/brands-we-love/modal-images/carhartt.jpg'),
		content: [
			"What’s better than recycling? Reusing. Carhartt is the kind of brand that gets passed down across generations of workers. That, combined with Carhartt’s  Code of Conduct that safeguards environmental protections, responsible working conditions and fundamental human rights.",
			"<b>BOTTOM LINE:</b> In the world of swag, Carhartt just works.",
			"<a href='/carhartt'>See More ></a>"
		]
	},
	{
		title: "Moleskine",
		alt: "Moleskine custom branded products and debossed notebooks decorated by Swagger.",
		anchor: "#moleskine",
		image: require('../../../images/brands-we-love/moleskine.jpg'),
		modalImage: require('../../../images/brands-we-love/modal-images/moleskine.jpg'),
		content: [
			"Moleskine inspires imaginations and fuels creativity–in style. They are committed to protecting the environment, by reducing the impact of its production processes. As a platform that celebrates long-term thinking, their additional focus on sustainability is the perfect match for the elegance of their timeless products.",
			"<b>BOTTOM LINE:</b> Combining creativity and a sustainable attitude, it’s no surprise Moleskine makes multi-tasking look…beautiful.",
			"<a href='/moleskine'>See More ></a>",
		]
	},
	{
		title: "Osprey",
		alt: "Osprey custom branded backpacks curated and decorated by Swagger.",
		anchor: "#osprey",
		image: require('../../../images/brands-we-love/osprey.jpg'),
		modalImage: require('../../../images/brands-we-love/modal-images/osprey.jpg'),
		content: [
			"At Osprey, “better chemistry” includes a respect for the connection between humans and the planet we live on. We love that Osprey makes “own-forever” backpacks. They’re in it for the long haul. Literally.",
			"<b>BOTTOM LINE:</b> Longevity never looked, felt or traveled better.",
			"<a href='/osprey'>See More ></a>",
		]
	},
	{
		title: "Urbanista",
		alt: "A collection of Urbanista headphones and earbuds curated by Swagger.",
		anchor: "#urbanista",
		image: require('../../../images/blog/urbanista/urbanista1-tall.png'),
		modalImage: require('../../../images/blog/urbanista/urbanista2.png'),
		content: [
			"Urbanista is amping up the audio game with their sleek, stylish headphones and earbuds. They're not just about sound—they're about making a statement with their Scandinavian design and cutting-edge technology. With a commitment to quality and innovation, they're music to the ears of anyone looking for custom swag that stands out.",
			"BOTTOM LINE: Urbanista is where style meets sound, proving that your audio experience can be as fashionable as it is immersive.",
			"<a href='/urbanista-headphones'>See More ></a>",
		]
	},
	{
		title: "Miir",
		alt: "MiiR custom branded mugs and water bottles decorated by Swagger.",
		anchor: "#miir",
		image: require('../../../images/brands-we-love/miir.jpg'),
		modalImage: require('../../../images/brands-we-love/modal-images/miir.jpg'),
		content: [
			"MiiR, the maker of premium stainless steel vessels that are sustainable and functional. And MiiR proudly donates to initiatives that promote clean water, a healthy environment and strong communities.",
			"<b>BOTTOM LINE:</b> Sustainability, great design and a grateful attitude add up to the perfect product–and partner brand.",
			"<a href='/miir'>See More ></a>"
		]
	},
	{
		title: "Day Owl",
		alt: "A professional setting with a Day Owl bag prominently displayed, curated by Swagger.",
		anchor: "#day-owl",
		image: require('../../../images/blog/day-owl/day-owl6.png'),
		modalImage: require('../../../images/blog/day-owl/day-owl5.png'),
		content: [
			"Day Owl is shaking up the sustainable fashion scene with a bang. They're all about turning recycled materials into ultra-cool bags that scream style and eco-consciousness. With a commitment to ethical production and a circular economy, they're not just another brand—they're a movement.",
			"BOTTOM LINE: Day Owl is where sustainability gets a stylish twist, proving that being eco-friendly has never looked so good!",
			"<a href='/day-owl'>See More ></a>"
		]
	},
	{
		title: "Tentree",
		alt: "Tentree custom branded apparel sourced and decorated by Swagger.",
		anchor: "#tentree",
		image: require('../../../images/brands-we-love/tentree.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/tentree.jpg'),
		content: [
			"Tentree puts the Earth first by making sustainable essentials with the smallest footprint, without sacrificing style, quality, comfort, or fit. Oh, and if you’re wondering about the name: Tentree plants 10 trees for every item purchased, highlighting the positive, lasting impact just one choice can have. Wear your impact.",
			"<b>BOTTOM LINE:</b>  Swag you can feel good in–and feel good about–that’s the Swagger way!",
			"<a href='/tentree'>See More ></a>",
		]
	},
	{
		title: "Topo",
		alt: "Topo custom branded athletic apparel curated and decorated by Swagger.",
		anchor: "#topo",
		image: require('../../../images/brands-we-love/topo.jpg'),
		modalImage: require('../../../images/brands-we-love/modal-images/topo.jpg'),
		content: [
			"Topo Designs is rooted in mountain culture, but their products are for the great outdoors – everywhere. Nostalgic for classic design and inspired by modern innovation, Topo Designs keeps an open mind when it comes to form and function.",
			"<b>BOTTOM LINE:</b>  Live on both sides of the fence, where life is always greener.",
			"<a href='/topo-designs.html'>See More ></a>"
		]
	},
	{
		title: "Original Favorites",
		alt: "Woman wearing a custom-designed Original Favorites hoodie by Swagger.",
		anchor: "#original-favorites",
		image: require('../../../images/blog/original/original1.png'),
		modalImage: require('../../../images/blog/original/original2.png'),
		content: [
			"Original Favorites is redefining style with a conscience. Committed to ethical production and environmental responsibility, they use premium materials like organic cotton and recycled fibers, ensuring each piece is not only fashionable but also sustainable. With a focus on transparency and fair labor practices, they're setting a new standard for the industry.",
			"<b>BOTTOM LINE:</b> Original Favorites proves that style and sustainability can go hand in hand, making eco-friendly fashion effortlessly chic.",
			"<a href='/original-favorites'>See More ></a>",
		]
	},
	{
		title: "Yeti",
		alt: "YETI custom branded water bottles and mugs curated and decorated by Swagger.",
		anchor: "#yeti",
		image: require('../../../images/brands-we-love/yeti.jpg'),
		modalImage: require('../../../images/brands-we-love/modal-images/yeti.jpg'),
		content: [
			"Yeti takes the idea of sustainability quite literally. They make products that last. Makes sense, given their mission is “to build the cooler you’d use every day if it existed.” Yeti really delivers on super durable, high quality in coolers and now drinkware, bags, gear and apparel.",
			"<b>BOTTOM LINE:</b> If you’re going to make something, make it last.",
			"<a href='/yeti'>See More ></a>",
		]
	},
	{
		title: "Econscious",
		alt: "Econscious custom branded apparel and accessories curated and decorated by Swagger.",
		anchor: "#econscious",
		image: require('../../../images/brands-we-love/econscious.jpg'),
		modalImage: require('../../../images/brands-we-love/modal-images/econscious.jpg'),
		content: [
			"Econscious puts two things first:  1) creating the perfect tee, and 2) creating positive impact on their global business partners. Their super soft, organic cotton creates long-lasting, responsibly-made apparel.",
			"<b>BOTTOM LINE:</b>  Go econscious and get all the elements of a truly great tee.",
			"<a href='/econscious'>See More ></a>"
		]
	},
	{
		title: "Timbuk2",
		alt: "Timbuk2 custom branded backpacks and travel accessories curated and decorated by Swagger.",
		anchor: "#timbuk2",
		image: require('../../../images/brands-we-love/timbuk2.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/timbuk2.jpg'),
		content: [
			"Timbuk2 gives a damn. Their words, not ours. And you can see their commitment in everything they do, including living, manufacturing and recycling all in San Francisco. One of the few that can claim a 30+ year legacy of making super high quality, highly reusable and recyclable bags.",
			"<b>BOTTOM LINE:</b>  Since 2014, ZERO Timbuk2 bags have been sent to landfills. Wow.",
			"<a href='/timbuk2'>See More ></a>"
		]
	},
	// {
	// 	title: "Alternative Apparel",
	// 	alt: "Custom branded Alternative Apparel curated and decorated by Swagger.",
	// 	anchor: "#alternative-apparel",
	// 	image: require('../../../images/brands-we-love/alternativeapparel.jpg'),
	// 	modalImage: require('../../../images/brands-we-love/modal-images/alternativeapparel.jpg'),
	// 	content: [
	// 		"Since 1995. That’s how long Alternative Apparel has been keeping textile waste out of landfills. With eco-materials and responsible manufacturing, plus product innovations and ingenious uses of wood and hemp.",
	// 		"<b>BOTTOM LINE:</b>  When it comes to styles we love and love for the environment, there is no better alternative.",
	// 		"<a href='/alternative-apparel'>See More ></a>"
	// 	]
	// },
	{
		title: "travismathew",
		alt: "Custom branded TravisMathew apparel curated and decorated by Swagger.",
		anchor: "#travismathew",
		image: require('../../../images/brands-we-love/travismathew.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/travismathew.png'),
		content: [
			"TravisMathew isn't just redefining golf apparel—they're setting a new standard for corporate swag. With sleek design and supreme functionality, every piece is a statement of style as much as it is a symbol of performance.",
			"<b>BOTTOM LINE</b>: Looking for custom swag that truly stands out? Choose TravisMathew for a sophisticated blend of fashion and function, curated by Swagger.",
			"<a href='/travismathew'>See More ></a>"
		]
	},
	{
		title: "AllMade",
		alt: "Allmade custom branded eco-friendly apparel curated and decorated by Swagger.",
		anchor: "#allMade",
		image: require('../../../images/brands-we-love/allmade.jpg'),
		modalImage: require('../../../images/brands-we-love/modal-images/allmade.jpg'),
		content: [
			"Allmade makes shirts out of recycled bottles, renewable trees and organic cotton. A combination that reduces carbon and waste, and saves water. They’re doing it right.",
			"<b>BOTTOM LINE:</b>  Creating a positive impact is possible, one amazing t-shirt at a time.",
			"<a href='/allmade-apparel.html'>See More ></a>"
		]
	},
	{
		title: "Karst",
		alt: "A stack of Karst notebooks, branded by Swagger.",
		anchor: "#karst",
		image: require('../../../images/brands-we-love/karst.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/karst.jpg'),
		content: [
			"K'arst transforms traditional paper products with their innovative use of recycled stone dust, making every notebook waterproof and remarkably durable. Ideal for those who value sustainability without sacrificing quality. Each purchase not only supports this commitment to eco-friendly practices but also contributes to tree planting initiatives, enhancing our environment one notebook at a time.",
			"<b>BOTTOM LINE:</b> Eco-friendly swag that redefines what it means to write responsibly.",
			"<a href='/karst'>See More ></a>"
		]
	},
	{
		title: "Modern Sprout",
		alt: "Modern sprout countertop planters with plants, branded by Swagger.",
		anchor: "#modern-sprout",
		image: require('../../../images/brands-we-love/modern-sprout.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/modern-sprout.png'),
		content: [
			"We love Modern Sprout for their innovative gardening solutions and dedication to sustainability. Their products make it easy to bring a bit of nature indoors, perfect for that \"grow green\" lifestyle. Plus, every purchase supports their initiatives like the One For One Tree Kit program, aimed at enhancing urban spaces and the environment.",
			"<b>BOTTOM LINE:</b> Eco-friendly swag that transforms any indoor space into a sustainable, thriving garden.",
			"<a href='/modern-sprout'>See More ></a>"
		]
	},
	{
		title: "Cotopaxi",
		alt: "Cotopaxi backpacks, branded by Swagger.",
		anchor: "#cotopaxi",
		image: require('../../../images/brands-we-love/cotopaxi.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/cotopaxi.jpg'),
		content: [
			"What’s more vibrant than your everyday adventure? Cotopaxi’s commitment to sustainability and bold style. Every stitch of their backpacks and hip packs tells a story of eco-conscious innovation, making them the perfect choice for anyone who values both functionality and environmental responsibility.",
			"<b>BOTTOM LINE:</b> In the world of swag, Cotopaxi is the ultimate source for dynamic, sustainable adventure gear.",
			"<a href='/cotopaxi'>See More ></a>"
		]
	},
	{
		title: "Fellow",
		alt: "Fellow coffee products, branded by Swagger.",
		anchor: "#fellow",
		image: require('../../../images/brands-we-love/fellow.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/fellow.jpg'),
		content: [
			"What’s better than good coffee? Sustainable brewing. Fellow is not just about crafting excellent coffee gear—it’s about setting a new standard for environmental responsibility in the coffee industry. With their sleek designs and eco-friendly materials, Fellow products are perfect for anyone looking to elevate their coffee routine while caring for the planet.",
			"<b>BOTTOM LINE:</b> In the world of swag, Fellow is the go-to for stylish, sustainable coffee essentials.",
			"<a href='/fellow-coffee-products'>See More ></a>"
		]
	},
	{
		title: "Known Supply",
		alt: "Known Supply tees in multiple colors, curated by Swagger.",
		anchor: "#known_supply",
		image: require('../../../images/brands-we-love/known-supply.jpg'),
		modalImage: require('../../../images/brands-we-love/modal-images/known-supply.jpg'),
		content: [
			"Known Supply didn't follow the crowd—they set a new standard. With care and creativity, they combine purpose with passion, crafting clothing that’s as much about conscious living as it is about effortless style.",
			"<b>BOTTOM LINE:</b> For custom apparel that tells your brand’s story while championing ethical fashion, think Known Supply.",
			"<a href='/known-supply'>See More ></a>"
		]
	},
	{
		title: "Hydroflask",
		alt: "Hydroflask bottles, curated by Swagger.",
		anchor: "#hydroflask",
		image: require('../../../images/brands-we-love/hydroflask.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/hydroflask.png'),
		content: [
			"<b>Elevate Your Hydration Experience:</b> Dive into the world of Hydro Flask, where innovative design meets sustainable living. Each bottle reflects a commitment to quality and the environment, featuring advanced TempShield™️ technology to keep your beverages perfectly tempered. Perfect for those who value style, function, and eco-conscious choices.",
			"<b>BOTTOM LINE:</b> With Hydro Flask, experience the pinnacle of stylish, sustainable drinkware that sets the standard in corporate swag.",
			"<a href='/hydroflask'>See More ></a>"
		]
	},
	{
		title: "Patagonia",
		alt: "Patagonia puffer jacket, curated by swagger.",
		anchor: "#patagonia",
		image: require('../../../images/brands-we-love/patagonia.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/patagonia.jpg'),
		content: [
			"Embrace the Great Outdoors with Patagonia: At Swagger, we champion brands that stand for more than just style. Patagonia’s sustainable apparel isn't just about looking good—it's about making good choices for our planet. Each piece of clothing and gear embodies a commitment to environmental responsibility and high-quality performance.",
			"<b>BOTTOM LINE:</b> For eco-friendly, versatile corporate swag that impresses and inspires, Patagonia is your go-to choice. Make a statement with your swag and show your commitment to sustainability.",
			"<a href='/patagonia'>See More ></a>"
		]
	},
	{
		title: "TravisMathew",
		alt: "TravisMathew pack, curated by Swagger.",
		anchor: "#travismathew",
		image: require('../../../images/brands-we-love/travismathew.png'),
		modalImage: require('../../../images/brands-we-love/modal-images/travismathew.png'),
		content: [
			"TravisMathew isn't just redefining golf apparel—they're setting a new standard for corporate swag. With sleek design and supreme functionality, every piece is a statement of style as much as it is a symbol of performance.",
			"<b>BOTTOM LINE:</b> Looking for custom swag that truly stands out? Choose TravisMathew for a sophisticated blend of fashion and function, curated by Swagger.",
			"<a href='/travismathew'>See More ></a>"
		]
	}
];