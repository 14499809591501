<template>
	<div class="page page--lookbooks">
		<header class="page__header">
			<h1>Case Studies</h1>
			<div class="copy">
				<p>Discover inspiring success stories! Explore case studies from brands like Dropbox, Gem, and Prizepicks that showcase how Swagger elevates brands with impactful swag solutions. This features Swagger Services like beautifully curated and designed custom swag, custom swag stores, merch stores, integrated marketing campaigns, and more.</p>
			</div>
		</header>
		<div class="page__content">
			<section class="section section--lookbooks">
				<div class="section__content">
					<div class="lookbooks-index">
						<CaseStudiesPreview v-for="cs in caseStudies" :lookbook="cs" />
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
	import caseStudies from './caseStudies';
	import CaseStudiesShow from './CaseStudiesShow.vue';
	import CaseStudiesPreview from './CaseStudiesPreview.vue';


	export default {
		name: 'CaseStudiesIndex',
		components: { CaseStudiesShow, CaseStudiesPreview },
		data () {
			return {
				caseStudies: caseStudies
			}
		},
	};
</script>

<style></style>