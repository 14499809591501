<template>
	<div class="page page--blog">
		<header class="page__header">
			<h1>Swagger Says</h1>
			<div class="copy">
				<p>Consider this blog a place for us to share the latest information and the greatest inspiration on swag with style, on sustainable swag that gives back. And always from our unique, boutique point of view.</p>
			</div>
		</header>
		<div class="page__content">
			<section class="section section--blog">
				<div class="section__content">

					<!-- TODO - this is the actual component, would be better to not have to recreate the above page markup, but we're dealing with vue-router temporarily -->
					<div class="blog-index">
						<div class="post-preview" v-for="post in posts">
							<div class="post-preview__image">
								<div class="image">
									<img v-bind:src="post.image">
								</div>
							</div>
							<div class="post-preview__content">
								<div class="title">
									<h2>{{post.title}}</h2>
								</div>
								<div class="date">
									<p>{{formatDate(post.date)}}</p>
								</div>
								<div class="excerpt">
									<p>{{post.description}}</p>
								</div>
								<div class="button">
									<!-- <router-link :to="{name: 'postShow', params: {slug: post.slug, post: post}}">Read More</router-link> -->
									<a :href="'/'+post.slug+'.html'">Read More</a>
									<!-- <a href="#" class="btn btn--secondary">Read More</a> -->
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
	import posts from './posts';

	posts.sort((a, b) => b.date - a.date);

	const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


	export default {
		name: 'PostIndex',
		data () {
			return {
				posts: posts
			}
		},
		methods: {
			formatDate(date) {
				return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
			}
		},
		// beforeMount() {}
	};
</script>

<style>
</style>
