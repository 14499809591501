<template>
	<ImageGrid :images="images" />
</template>

<script>
	import ImageGrid from '../ImageGrid.vue'
	import images from './images';

	export default {
		name: 'GotSwaggerImageWrapper',
		components:{ ImageGrid },
		data () {
			return {
				images: images
			}
		}
	};
</script>