export default [
	{
		title: 'Dropbox Pop-Up Shop',
		description: 'Eager to elevate their employee gift experience, Dropbox partnered with Swagger for a limited-edition pop-up shop. Dive into their case study to discover how we delivered impactful swag globally, eliminated waste, and increased employee satisfaction – all within a tight timeframe!',
		image: require('../../../images/case-studies/dropbox.png'),
		flippingBookId: '485136956',
		slug: 'dropbox-pop-up-shop',
	},
	{
		title: 'Prizepicks Merch Store',
		description: 'As the leading fantasy sports operator in North America, PrizePicks was looking to enhance their fan engagement through branded merchandise. Swagger created a uniquely designed online store featuring a dedicated "Taco Tuesday" product collection, perfectly resonating with their 2 million-strong audience.',
		image: require('../../../images/case-studies/prizepicks.png'),
		flippingBookId: '484882740',
		slug: 'prizepicks-merch-store',
	},
	{
		title: 'Gem Integrated Marketing Campaign',
		description: 'Gem wanted personalized gifting with a fun twist to boost sales. Swagger created a custom HubSpot redemption page and curated the perfect treats (including "Ted Lasso Biscuits with the Boss!") – leading to sweeter connections and higher conversion rates.',
		image: require('../../../images/case-studies/gem.png'),
		flippingBookId: '485298137',
		slug: 'gem-integrated-marketing-campaign',
	},
	{
		title: 'Fastly Customer Gifting Site',
		description: "Fastly's growing partner network demanded a scalable gifting solution. Swagger provided a custom logo, curated sustainable gift kits, and efficient management tools, fostering deeper connections while aligning with Fastly's eco-conscious values.",
		image: require('../../../images/case-studies/fastly.png'),
		flippingBookId: '484886824',
		slug: 'fastly-customer-gifting-site',
	},
	{
		title: 'eBay - Creative Design',
		description: 'To celebrate their Seattle office, eBay partnered with Swagger on a custom capsule collection. Swagger created a unique Space Needle-themed logo for beanies, mugs, water bottles, and more to fuel local pride and team spirit.',
		image: require('../../../images/case-studies/ebay.png'),
		flippingBookId: '877929241',
		slug: 'ebay-creative-design',
	},
	{
		title: 'TAIT Holiday Shop',
		description: 'TAIT partnered with Swagger to deliver impactful global gifts to hundreds of clients. Clients browsed pre-made themed kits, ordered online, and received same-week delivery in custom boxes. Plus, they could even choose to donate to "Diversify the Stage" instead of receiving a gift.',
		image: require('../../../images/case-studies/tait.png'),
		flippingBookId: '485366556',
		slug: 'tait-holiday-shop',
	},
	{
		title: 'Webflow - Sales Kickoff Swag',
		description: "From sales kickoffs to unforgettable offsites, Swagger fueled Webflow's team spirit with custom swag experiences. Tailored kits, locally-sourced treats, and branded gear kept teams engaged, motivated, and celebrating successes.",
		image: require('../../../images/case-studies/webflow.png'),
		flippingBookId: '1015369679',
		slug: 'webflow-sales-kickoff-swag',
	},
];
