import * as Vue from 'vue';
// import * as VueRouter from 'vue-router';

import CarouselInfinite from './scripts/components/CarouselInfinite.vue';
import CarouselHero from './scripts/components/CarouselHero.vue';
import InstaFeed from './scripts/components/InstaFeed.vue';

import ImageGridBwl from './scripts/components/ImageGridBwl/Component.vue';
import ImageGridOurWork from './scripts/components/ImageGridOurWork/Component.vue';

import Blog from './scripts/components/blog/Blog.vue';
import PostIndex from './scripts/components/blog/PostIndex.vue';
import PostShow from './scripts/components/blog/PostShow.vue';

import LookbookIndex from './scripts/components/lookbooks/LookbookIndex.vue';
import LookbookShow from './scripts/components/lookbooks/LookbookShow.vue';

import CaseStudiesIndex from './scripts/components/caseStudies/CaseStudiesIndex.vue';
import CaseStudiesShow from './scripts/components/caseStudies/CaseStudiesShow.vue';

import ContactForm from './scripts/components/contactForm/ContactForm.vue';

//// init components
// https://vueschool.io/articles/vuejs-tutorials/the-benefits-of-the-vue-3-app-initialization-code/

// image grids + modals for Sustainable Style & They've Got Swagger
const bwlGridApp = Vue.createApp(ImageGridBwl).mount('#sustainableImageGrid');
const ourWorkGridApp = Vue.createApp(ImageGridOurWork).mount('#ourWorkImageGrid');

// home page carousels
const heroCarouselApp = Vue.createApp(CarouselHero).mount('#carouselHero');
const inspirationCarouselApp = Vue.createApp(CarouselInfinite).mount('#carouselInspiration');

// home page instagram feed
const instaFeedApp = Vue.createApp(InstaFeed).mount('#instaFeedTarget');

// blog
const blog = Vue.createApp(PostIndex).mount('#blogIndex');

// lookbooks
const lookbooksApp = Vue.createApp(LookbookIndex).mount('#lookbooks');

const lookbookMountEl = document.querySelector('#lookbooks-show');

if (lookbookMountEl) {
	const lookbooksShowApp = Vue.createApp(LookbookShow, {...lookbookMountEl.dataset}).mount('#lookbooks-show');
}

// case studies
const caseStudiesApp = Vue.createApp(CaseStudiesIndex).mount('#caseStudies')

const caseStudyMountEl = document.querySelector('#caseStudies-show');
if (caseStudyMountEl) {
	const caseStudiesShowApp = Vue.createApp(CaseStudiesShow, {...caseStudyMountEl.dataset}).mount('#caseStudies-show');
}

// contact form
const contactFormApp = Vue.createApp(ContactForm).mount("#contactFormApp");

// const lookbookRoutes = [
// 	{
//         path: '/',
//         // name: 'LookbookIndex',
//         component: LookbookIndex
//     },
//     {
//         path: '/:name',
//         // name: 'LookbookShow',
//         component: LookbookShow
//     },
// ];

// const lookbookRouter = VueRouter.createRouter({
// 	history: VueRouter.createWebHistory(),
// 	routes: lookbookRoutes
// });

// const lookbooksApp = Vue.createApp(LookbookIndex).use(lookbookRouter).mount('#lookbooks');


// sticky header scroll behavior
const headerClasses = document.getElementById('mainHeader')?.classList;

document.addEventListener('scroll', (e) => {
	// sleep(100);
	const scrollPos = window.scrollY;
	const thresholdDown = 10;
	const thresholdUp = 2;

	if (scrollPos > thresholdDown && !headerClasses.contains('sticky')) {
		headerClasses.add('sticky')
	} else if (scrollPos < thresholdUp && headerClasses.contains('sticky')) {
		headerClasses.remove('sticky');
	}
});
