<!-- form for /get-started ("contact_form") -->
<template>
	<form class="form">
		<div class="side-by-side">
			<div class="form-element form-element--text">
				<label for="form[first_name]">First name*</label>
				<input type="text" placeholder="First Name" name="form[first_name]" id="form[first_name]"/>
				<span v-if="errors.first_name" v-for="err in errors.first_name" class="form-error">{{err}}</span>
			</div>
			<div class="form-element form-element--text">
				<label for="form[last_name]">Last name*</label>
				<input type="text" placeholder="Last Name" name="form[last_name]" id="form[last_name]"/>
				<span v-if="errors.last_name" v-for="err in errors.last_name" class="form-error">{{err}}</span>
			</div>
		</div>
		<div class="side-by-side">
			<div class="form-element form-element--text">
				<label for="form[company_name]">Company name*</label>
				<input type="text" placeholder="Company Name" name="form[company_name]" id="form[company_name]"/>
				<span v-if="errors.company_name" v-for="err in errors.company_name" class="form-error">{{err}}</span>
			</div>
			<div class="form-element form-element--text">
				<label for="form[email]">Company email*</label>
				<input type="email" placeholder="bobby@example.com" name="form[email]" id="form[email]"/>
				<span v-if="errors.email" v-for="err in errors.email" class="form-error">{{err}}</span>
			</div>
		</div>
		<div class="form-element form-element--text">
			<label for="form[project_info]">What can you tell us about your project?*</label>
			<textarea placeholder="We want to know it all! Spill it." name="form[project_info]" id="form[project_info]" />
			<span v-if="errors.project_info" v-for="err in errors.project_info" class="form-error">{{err}}</span>
		</div>
		<!-- <div class="form-element form-element--text">
			<label for="form[source]">How Did You Hear About Us?</label>
			<select name="form[source]" id="form[source]">
				<option></option>
				<option>Referral</option>
				<option>LinkedIn</option>
				<option>Instagram</option>
				<option>Google Search</option>
				<option>Email</option>
			</select>
		</div> -->
		<div class="form-element form-element--text">
			<label for="form[referral]">Who can we thank for sending you our way?</label>
			<input type="text" name="form[referral]" id="form[referral]" />
		</div>
		
		<!-- receives GCLID parameter from cookie if present -->
		<div class="form-element form-element--hidden">
			<input id="inputMarketingParams" type="text" name="form[marketingParams]" v-model="form.marketingParams"/>
		</div>
		
		<!-- receives recaptcha token & action for backend assessment -->
		<div class="form-element form-element--hidden">
			<input id="recaptchaData" type="text" name="form[recaptchaData]" v-model="form.recaptchaData" />
		</div>

		<div class="form-element form-element--submit">
			<button class="btn btn--primary" aria-label="Submit Form" :onclick="handleSubmit" type="button">Let's go</button>
		</div>
	</form>
</template>

<script>
	export default {
		name: 'ContactForm',
		data () {
			return {
				form: {
					recaptchaData: "",
					marketingParams: "",
				},
				errors: {}
			}
		},
		mounted () {
			// grab and parse marketingParams if present
			function consumeParamCookie() {
				var name = 'paramObj=';
				// JSON.parse(do)
				var cookie = document.cookie.split(';');
				for(var i=0;i < cookie.length;i++) {
					var c = cookie[i];
					while (c.charAt(0)==' '){
						c = c.substring(1, c.length);
					}
					if (c.indexOf(name) == 0){
						return c.substring(name.length, c.length);
					}
				}
				return "";
			}
			
			this.form.marketingParams = consumeParamCookie();
		},
		methods: {
			async handleSubmit(e) {
				e.preventDefault();

				// perform recaptcha assessment
				if (window.grecaptcha) {
					const vScope = this;
					const action = 'lead_form_submit';
					await window.grecaptcha.enterprise.execute('6LdhzP0iAAAAAGI-pXgR84c7TAfKpy92oINxSpls', {action: action}).then(function(token) {
						const recaptchaData = JSON.stringify({ token: token, action: action });
						vScope.form.recaptchaData = recaptchaData;
					});
				}

				const formData = new FormData(e.target.form);
				
				let res = await fetch('/contact_form', {
					method: 'POST',
					body: formData
				});

				const body = await res.json();

				if (res.status == 422) {
					// unprocessable
					this.errors = body.errors;
				} else {
					window.location.href = body.uri
				}
			}
		},
	};
</script>

<style>
</style>
