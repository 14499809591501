<template>
	<div class="page page--lookbooks">
		<header class="page__header">
			<h1>Lookbooks</h1>
			<!-- <div class="copy">
				<p></p>
			</div> -->
		</header>
		<div class="page__content">
			<section class="section section--lookbooks">
				<div class="section__content">
					<div class="lookbooks-index">
						<LookbookPreview v-for="lb in lookbooks" :lookbook="lb" />
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
	import lookbooks from './lookbooks';
	import LookbookShow from './LookbookShow.vue';
	import LookbookPreview from './LookbookPreview.vue';


	export default {
		name: 'LookbookIndex',
		components: { LookbookShow, LookbookPreview },
		data () {
			return {
				lookbooks: lookbooks
			}
		},
	};
</script>

<style></style>