<template>
	<div class="feed" v-if="posts.length">
		<div class="feed__item" v-for="post in posts">
			<a :href="post.permalink" target="_blank" :title="post.caption.substring(0, 60) + '...'">
				<div class="igimage" v-bind:style="{backgroundImage: 'url('+post.mediaUrl+')'}"></div>
				<!-- <img :src="post.mediaUrl" :title="post.caption.substring(0, 60) + '...'"/> -->
			</a>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'InstaFeed',
		data () {
			return {
				posts: []
			}
		},
		methods: {
			async getFeed() {
				const res = await fetch('https://feeds.behold.so/lZCvYtzUKl3KhSTuCbLf');
				const data = await res.json();

				this.posts = data;
			}
		},
		beforeMount() {
			this.getFeed();
		}
	};
</script>

<style>
	/* styles at src/styles/vue-components/feed */
</style>
