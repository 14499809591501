<template>
	<div class="carousel-wrap">
		<div class="carousel-wrap__carousel">
			<div id="infiniteCarousel">
				<div v-for="image in images">
					<div class="slide-inner">
						<img :src="image.src" :alt="image.alt" />
					</div>
				</div>
				<!-- TODO - remove duplicated markup -->
				<div v-for="image in images">
					<div class="slide-inner">
						<img :src="image.src" :alt="image.alt" />
					</div>
				</div>
			</div>
		</div>
		<nav class="carousel-wrap__nav">
			<button @click='multiSiema.prev()' aria-label="Previous images">&lt;</button>
			<button @click='multiSiema.next()' aria-label="Next images">&gt;</button>
		</nav>
	</div>
</template>

<script>
	import Siema from 'siema'

	import googleImage from '../../images/home/carousel/google.png';
	import untamedImage from '../../images/home/carousel/untamedplanet.jpg';
	import sertifiImage from '../../images/home/carousel/sertifi.png';
	import webflowImage from '../../images/home/carousel/webflow.png';
	import spotlyfeImage from '../../images/home/carousel/spotlyfe.jpg'

	export default {
		name: 'CarouselInfinite',
		data () {
			return {
				images: [
					{
						src: googleImage,
						alt: 'Sherpa blanket with Google Super G logo embroidered in tone on tone grey curated and decorated by Swagger.'
					}, 
					{
						src: webflowImage,
						alt: 'MiiR custom branded wine bottle set laser etched for Webflow and Napa Maptote Bag sourced and decorated by Swagger.'
					},
					{
						src: sertifiImage,
						alt: 'Custom branded Converse shoes for Sertifi designed and produced by Swagger.'
					},
					{
						src: untamedImage,
						alt: 'Rumpl custom branded blanket embroidered with Untamed Planet logo by Swagger.'
					}, 
					{
						src: spotlyfeImage,
						alt: 'Swag box for new hires filled with custom branded apparel including: custom branded tumbler, custom socks, beanie with tag, custom designed notebook, custom mousepad curated and kitted by Swagger.'
					}
				],
				multiSiema: {
					// these get overwritten when siema is initialized in mounted() below
					next: () => console.log('next'),
					prev: () => console.log('prev')
				}
			}
		},
		mounted () {
			this.multiSiema = new Siema({
				selector: '#infiniteCarousel',
				perPage: {
					0: 2,
					751: 5,
				},
				loop: true
			});

			// "autoplay"
			setInterval(() => this.multiSiema.next(), 3000);
		}
	};
</script>
