<template>
	<a class="lb-preview" :href="'/lookbooks/'+lookbook.slug+'.html'">
		<!-- <h1>Any</h1> -->
		<div class="lb-preview__image">
			<div class="image">
				<img v-bind:src="lookbook.image">
			</div>
		</div>
		<div class="lb-preview__content">
			<div class="title">
				<h2>{{lookbook.title}}</h2>
			</div>
			<!-- <div class="excerpt">
				<p>{{lookbook.description}}</p>
			</div> -->
			<!-- <div class="button">
				<a :href="'/lookbooks/'+lookbook.slug+'.html'">View Lookbook</a>
			</div> -->
		</div>
	</a>
</template>

<script>
	export default {
		name: 'LookbookPreview',
		props: ['lookbook'],
	};
</script>

<style></style>