<template>
	<div class="page page--lookbooks-show">
		<header class="page__header">
			<h1>{{lookbook.title}}</h1>
			<div class="copy">
				<p>{{lookbook.description}}</p>
			</div>
		</header>
		<div class="page__content">
			<section class="section section--lookbooks">
				<div class="section__content">
					<a :href=lookbookLink class="fbo-embed" data-fbo-id="f37d30f2ac" data-fbo-ratio="3:2" data-fbo-lightbox="yes" data-fbo-width="100%" data-fbo-height="auto" data-fbo-version="1" style="max-width: 100%">{{lookbook.title}}</a>
				</div>
			</section>
			<section class="section section--related">
				<div class="section__content">
					<div class="lookbooks-index">
						<LookbookPreview v-for="lb in related" :lookbook="lb" />
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script>
	import caseStudies from './caseStudies';
	import CaseStudiesPreview from './CaseStudiesPreview.vue';

	export default {
		name: 'CaseStudiesShow',
		props: ['slugref'],
		components: { CaseStudiesPreview },
		data () {
			const thisCs = caseStudies.find((lb) => {
				return lb.slug === this.slugref;
			});
			
			let related = caseStudies.filter((lb) => {
				// don't show current lookbook OR holiday 2022 in the recs. 
				// @note - this will need to be modified when we have more than 5 total lookbooks
				return lb.slug !== this.slugref && lb.slug !== '2022-holiday-lookbook';
			});
			related = related.slice(0, 3); // restrict to first 3 that match

			return {
				lookbook: thisCs,
				related: related,
				lookbookLink: `https://lookbooks.giveswagger.com/view/${thisCs.flippingBookId}`,
				embedLink: `https://online.flippingbook.com/EmbedScriptUrl.aspx?m=redir&hid=${thisCs.flippingBookId}`,
			}
		},
		mounted() {
			let embedScript = document.createElement('script');
			embedScript.setAttribute('src', this.embedLink);
			document.head.appendChild(embedScript);
		},
	};
</script>

<style></style>